import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Section } from '../components/components';
import '../styles/404.css';
import styled from 'styled-components';
import TLink from '../components/TLink';

const StyledList = styled.ul`
  list-style: none;
  & li a {
    color: white;
    text-decoration: none;
  }
`;
const CustomSection = styled(Section)`
  position: relative;
  margin-top: 80px;
  height: 440px;
`;

const NotFoundPage = () => (
  <Layout navbarBackground="rgba(255, 255, 255, 0.8)" navbarColor="rgb(60, 67, 86)">
    <SEO title="404: Not found" />
    <CustomSection>
      <h1 className="error-shadow">404</h1>
      <h1 className="h">404</h1>

      <span className="error-text">
        We've searched all around the solar system<br></br>and we didn't find that page<br></br>
        <StyledList>
          <li>Here are some helpful links instead:</li>
          <li>
            <TLink to="/">Home</TLink>
          </li>
          <li>
            <TLink to="/about/">About</TLink>
          </li>
          <li>
            <TLink to="/contacts/">Contact</TLink>
          </li>
          <li>
            <TLink to="#">Sitemap</TLink>
          </li>
        </StyledList>
      </span>

      <div className="space"></div>

      <img className="moon" src="https://i.postimg.cc/nzX8ZVhN/moon-3.png" />
      <img className="earth" src="https://i.postimg.cc/hvSM1p7g/worldwide-2.png" />

      <div className="meteors">
        <div className="m1"></div>
        <div className="m2"></div>
        <div className="m3"></div>
        <div className="m4"></div>
      </div>
    </CustomSection>
  </Layout>
);

export default NotFoundPage;
